import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { UserSettings, useUserSettings } from '../graphql';
import useAccessToken from './useAccessToken';
import useCurrentUser from './useCurrentUser';
import { useQueryStatusLogging } from './useQueryStatusLogging';

export default function useSettings() {
  const { data: accessToken } = useAccessToken();
  const { data: currentUserData } = useCurrentUser();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const initialData: UserSettings = {
    settings: {
      data: [
        {
          key: 'date_format',
          value: 'DD/MM/Y',
          __typename: 'Setting',
        },
        {
          key: 'time_format',
          value: 'HH:mm',
          __typename: 'Setting',
        },
        {
          key: 'first_day_of_week',
          value: '1',
          __typename: 'Setting',
        },
        {
          key: 'unit',
          value: 'metric',
          __typename: 'Setting',
        },
        {
          key: 'decimal_symbol',
          value: '.',
          __typename: 'Setting',
        },
        {
          key: 'digit_grouping_symbol',
          value: ',',
          __typename: 'Setting',
        },
      ],
    },
  };

  const queryInfo = useUserSettings(
    graphQLClient,
    {},
    {
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      initialData,
      enabled: !!accessToken,
    },
  );

  useQueryStatusLogging(queryInfo, 'CurrentUserData');

  type SettingsKeyType =
    | 'date_format'
    | 'time_format'
    | 'first_day_of_week'
    | 'unit'
    | 'decimal_symbol'
    | 'digit_grouping_symbol';

  const getSettingByKey = (setting_key: SettingsKeyType) => {
    const { data } = queryInfo;

    const setting = data?.settings?.data.find((item) => item.key === setting_key);

    if (setting) {
      if (setting_key === 'date_format') {
        const dateFormatMap = new Map([
          ['DD/MM/Y', 'DD/MM/YYYY'],
          ['Y-MM-DD', 'YYYY-MM-DD'],
          ['D/M/Y', 'D/M/YYYY'],
        ]);

        if (setting.value) {
          const _dateFormatMap = dateFormatMap.get(setting.value);

          if (_dateFormatMap) {
            return _dateFormatMap;
          }
        }
      }

      return setting.value || undefined;
    }

    if (currentUserData?.authUser && setting_key in currentUserData.authUser) {
      return (currentUserData?.authUser as any)[setting_key];
    }

    const initialSetting = initialData.settings?.data.find((item) => item.key === setting_key);

    if (initialSetting) {
      return initialSetting.value || undefined;
    }

    return undefined;
  };

  return {
    ...queryInfo,
    getSettingByKey,
  };
}
